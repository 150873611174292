<template>
    <div class="container ">
        <br />
        <div class="box"> 
            <h5>Update Account</h5>
            <p>Please fill out the information below to complete your profile!</p><br />
            <label >Your Full Name: </label>
    <input type="text" class="form-control" v-model="name"  placeholder=" Name">
    <br />
          <button @click="updateUser" class="btn btn-success">Complete Update</button>
        </div>
      
           
    </div>
</template>
<script>
import firebase from "firebase";
var db = firebase.firestore();


export default {
    methods:{
        updateUser: async function(){
            await firebase.auth().currentUser.updateProfile({displayName: this.name})

          await db.collection("Users").doc(this.user.uid).set({displayName: this.name}, { merge: true })

              this.$router.push('/dashboard')
        },
    },
    mounted: async function(){
this.user = await  new Promise((resolve, reject) => {
     const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
     }, reject);
  });


    },
    components:{
        
},
    data() {
    return {
        user: undefined,
        name: ""
    }
    }
}
</script>

<style scoped>
.box{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
   
}
</style>